/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
.item-empty .hover {
  display: none !important;
}
#subnavi .cb-invisible {
  display: block !important;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
  line-height: 1;
}
.sub1 > .item {
  margin-top: 12px;
  margin-bottom: 14px;
  padding: 0 11px 0 12px;
  border-left: 1px solid #fff;
}
.sub1 > .item.exit {
  padding-right: 0;
}
.sub1 > .item.init {
  border: none;
}
div.sub1 .menu {
  font-size: 18px;
  font-family: 'Open Sans', helvetica, sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #ffb3a4;
}
/* LEVEL 2 */
div.sub2 {
  margin-left: 12px;
}
.sub2 > .item {
  position: relative;
}
div.sub2 .menu {
  font-size: 18px;
  font-family: 'Open Sans', helvetica, sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 17px;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #ffb3a4;
}
div.sub2 a.path {
  color: #ffb3a4;
}
/* LEVEL 3 */
#navigation div.sub3 {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -47px;
  width: 300px;
  padding-top: 25px;
  background: url(/images/corner-subnavi.png) no-repeat 30px 10px;
}
#navigation .sub3 > .item {
  width: 100%;
  padding: 7px 0 0;
  background: url(/images/subnavi.png) repeat 0 0;
}
#navigation .sub3 > .item.init {
  padding-top: 21px;
}
#navigation .sub3 > .item.exit {
  padding-bottom: 21px;
}
#navigation div.sub3 .menu {
  font-size: 13px;
  color: #000;
  padding: 0 25px;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-weight: 600;
}
#navigation div.sub3 .menu:hover,
#navigation div.sub3 .menu:focus {
  color: #ff340b;
}
#navigation div.sub3 a.path {
  color: #ff340b;
}
/* SUB3 Subnavi */
#subnavi div.sub3 {
  float: left;
  margin-top: 145px;
  width: 100%;
}
#subnavi div.sub3 > .item {
  width: 100%;
  padding: 6px 0;
  border-top: 1px solid #cfcfcf;
}
#subnavi div.sub3 > .item.exit {
  border-bottom: 1px solid #cfcfcf;
}
#subnavi div.sub3 .menu {
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
#subnavi div.sub3 .menu:hover,
#subnavi div.sub3 .menu:focus {
  color: #ff340b;
}
#subnavi div.sub3 a.path {
  color: #ff340b;
}
/* LEVEL 4 */
#navigation div.sub4 {
  display: none;
}
#navigation .sub4 > .item {
  width: 100%;
  padding: 5px 0;
}
#navigation .sub4 > .item.exit {
  padding-bottom: 0;
}
#navigation div.sub4 .menu {
  font-weight: normal;
  text-transform: none;
}
#navigation div.sub4 a.path {
  color: #ff340b;
}
/* SUB4 Subnavi */
#subnavi div.sub4 {
  width: 100%;
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 4px;
  border-top: 1px solid #cfcfcf;
}
#subnavi div.sub3 .item.item-empty div.sub4 {
  display: none !important;
}
#subnavi div.sub4 > .item {
  width: 100%;
}
#subnavi div.sub4 .menu {
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}
#services {
  float: left;
  margin-top: 75px;
  width: 100%;
  line-height: 1;
  text-align: center;
}
#services .meta {
  color: #fff;
  line-height: 1.2;
  padding: 0 5px;
  text-decoration: none;
  border-right: 1px solid #fff;
}
#services .meta.auth {
  padding-right: 0;
  border: none;
}
#services .meta.service_kalibrierstelle {
  padding-left: 0;
}
#services .meta:hover,
#services .meta:focus {
  text-decoration: underline;
}
#services .meta.home,
#services .meta.service_custContact,
#services .meta.service_Downloads {
  display: none;
}
#services2 {
  float: right;
  margin-right: 18px;
}
#services2 .meta {
  display: block;
  float: left;
  width: 15px;
  height: 24px;
  text-indent: -9999px;
  overflow: hidden;
  background: url(/images/services2.png) no-repeat;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  margin-top: 10px;
}
#services2 .meta.mail {
  background-position: -15px 0;
  width: 23px;
  margin-top: 13px;
  height: 18px;
  margin-left: 20px;
}
#services2 .meta:hover,
#services2 .meta:focus {
  opacity: 0.6;
}
#services3 {
  position: absolute;
  right: 0;
  top: 24px;
}
#services3 .meta {
  display: block;
  float: left;
  width: 75px;
  height: 75px;
  text-indent: -9999px;
  overflow: hidden;
  background: url(/images/ilac2.svg) no-repeat 50% 50%;
  background-size: 70px 70px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  pointer-events: none;
}
#services3 .meta.service_sc {
  margin-left: 16px;
  background: url(/images/sas.svg) no-repeat 50% 50%;
  background-size: 70px 70px;
}
#services3 .meta:hover,
#services3 .meta:focus {
  opacity: 0.6;
  opacity: 1;
}
#services4 {
  position: absolute;
  right: 0;
  bottom: 70px;
  line-height: 1;
}
#services4 .meta {
  padding: 0 4px 0 6px;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 1;
  font-size: 13px;
  color: #2f2f2f;
  text-decoration: none;
  border-left: 1px solid #2f2f2f;
}
#services4 .meta.home {
  border: none;
}
#services4 .meta:hover,
#services4 .meta:focus {
  text-decoration: underline;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  overflow: visible;
  width: 100%;
  width: 982px;
  max-width: 92%;
}
#home {
  float: left;
  margin-top: 34px;
}
#find {
  display: block;
}
.cb-layout2 #subnavi {
  display: block;
}
#header {
  margin-top: 0;
}
.cb-cart-text-holder {
  display: block;
}
#expo div.link {
  bottom: 20px;
  width: 100%;
  margin: 0 auto;
}
#find {
  position: absolute;
  right: 0;
  bottom: 20px;
  margin-top: 0;
  margin-left: 0;
  width: auto;
  margin-right: 0;
}
#find .find {
  position: static;
  float: right;
}
#searchfield {
  width: 220px;
  padding: 6px 10px;
}
#content {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
#maincontent {
  width: 101.42566191%;
  margin-left: -0.70281124%;
}
.cb-layout2 #maincontent {
  float: right;
  margin-right: -1.01832994%;
  width: 73.3197556%;
  margin-left: 0;
}
#head {
  width: 98.59437751%;
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  margin-top: 80px;
}
.vcard {
  width: 255px;
  text-align: left;
}
.copyright {
  text-align: left;
  width: auto;
}
.phone {
  display: block;
}
.mobile-phone {
  display: none;
}
@media (min-width: 1024px) {
  .footarea {
    width: 104.07331976%;
    margin-left: -1.95694716%;
    display: flex;
    flex-wrap: wrap;
  }
  .footpart {
    margin-left: 1.95694716%;
    margin-right: 1.95694716%;
  }
  .footpart.footpart--slim {
    width: 25.44031311%;
  }
  .footpart.footpart--half {
    width: 66.73189824%;
  }
  .footpart.footpart--wide {
    width: 96.08610568%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.70281124%;
  margin-left: 0.70281124%;
}
.area .part,
.area > .grid table {
  margin-right: 0.70281124%;
  margin-left: 0.70281124%;
  width: 98.59437751%;
}
.area .tiny {
  width: 48.59437751%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2.10843373%;
  margin-left: 2.10843373%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 95.78313253%;
}
.area > .slim .tiny {
  width: 95.78313253%;
}
.cb-layout2 .area {
  width: 100%;
}
.cb-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area h2,
.cb-layout2 .area .foot {
  margin-right: 1.38888889%;
  margin-left: 1.38888889%;
}
.cb-layout2 .area .part,
.cb-layout2 .area > .grid table {
  margin-right: 1.38888889%;
  margin-left: 1.38888889%;
  width: 97.22222222%;
}
.cb-layout2 .area .tiny {
  width: 47.22222222%;
}
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  width: 97.22222222%;
}
.cb-layout2 .area > .slim .tiny {
  width: 30.55555556%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile input.numb.grow {
  position: absolute !important;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 100%;
}
/* SEAM */
.cb-layout1 div.wide.seam {
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  width: 98.59437751%;
}
.cb-layout1 div.wide.seam div.head h2,
.cb-layout1 div.wide.seam div.part {
  margin-left: 2.03665988%;
  margin-right: 2.03665988%;
}
.cb-layout1 div.wide.seam div.head h2.tall,
.cb-layout1 div.wide.seam div.part.tall {
  width: 95.92668024%;
}
.cb-layout1 div.wide.seam div.head h2.tiny,
.cb-layout1 div.wide.seam div.part.tiny {
  width: 45.92668024%;
}
.cb-layout1 div.slim.seam {
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  width: 31.92771084%;
}
.cb-layout1 div.slim.seam div.head h2,
.cb-layout1 div.slim.seam div.part {
  margin-left: 6.28930818%;
  margin-right: 6.28930818%;
  width: 87.42138365%;
}
.cb-layout1 div.flat {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: none;
  border-bottom: none;
  padding: 0;
}
.cb-layout2 div.main div.slim div.tall {
  width: 63.88888889%;
}
div.payp .name {
  margin-bottom: 0;
}
div.payp .ctrl {
  margin-top: 0;
}
.cb-layout1 h1 {
  border-top: none;
  padding-top: 0;
}
div.wide h2 {
  border-top: none;
  padding-top: 25px;
  margin-left: 7px;
  margin-right: 7px;
  padding-left: 0;
  padding-right: 0;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.cb-shoppingcart {
  left: 25%;
  margin-left: 0;
  width: 50%;
  min-width: 340px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 56%;
}
#disp.shop .cb-shop-currency > select {
  width: 60%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */